import React from 'react';
import SliderHome from "../Includes/SliderHome";
import ScheduleBanner from "../Includes/ScheduleBanner";
import OfferServices from "../Includes/OfferServices";
import Expertise from "../Includes/Expertise";
import Service from "../Includes/Service";
import Testimonial from "../Includes/Testimonial";
import Blog from "../Includes/Blog";
import Map from "../Includes/Map";
import CountryLink from "../Includes/CountryLink";

const Home = () => {
    return (
        <div>
            <SliderHome/>
            <ScheduleBanner/>
            <OfferServices/>
            <Expertise/>
            <Service/>
            <Testimonial/>
            <Blog/>
            <CountryLink/>
            <Map/>
        </div>
    );
};

export default Home;
