import React from 'react';
import img_1 from "../../Assets/Img/service/CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg";
import img_4 from "../../Assets/Img/service/CleaningServicesShermanOaksHousekeeperShermanOaks.jpg";
import img_5 from "../../Assets/Img/service/CleaningServicesTarzanaHousekeeperTarzana.jpg";
import img_6 from "../../Assets/Img/service/CleaningServicesWestHollywoodHousekeeperWestHollywood.jpg";
import img_2 from "../../Assets/Img/service/CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg";
import img_3 from "../../Assets/Img/service/CleaningServicesBelAirHousekeeperBelAir.jpg";

const FloorCleaning = () => {
    return (
        <>
            <div className={'container-fluid p-0'}>
                <div className="common-banner">
                    <img src={img_1} alt={'services'} className={'img-fluid'}/>
                    <div className="content">
                        <h1 className={'display-1 text-uppercase'}>Floor Cleaning</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_4} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_5} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_6} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={'display-1 text-uppercase text-center'}>Floor Cleaning</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap service-page">
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> The professional floor cleaner offers you options for deep cleaning your home whether you are moving in, moving out, flood cleaning or seeking to just give it that deep, down clean feeling. Spring cleaning is often the best excuse for hiring a professional to deep clean the carpets, the tile and the grout. Most home owners have multiple types of floors in their homes, so the professional should provide multiple types of floor cleaning services.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> At HOPE Home Services (818) 773-7599, you will get the best treatment for your floors because we have the experience and knowledge necessary to use the appropriate products and correctly manage your flooring materials.</p>

            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_1} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_2} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_3} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FloorCleaning;
