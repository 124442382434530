import React, {useState} from 'react';
import img1 from './../../Assets/Img/banner/1.CleaningServiceWestHollywoodHousekeeperServiceWestHills.jpg'
import img2 from './../../Assets/Img/banner/2.CleaningServiceWestHillsHousekeeperServiceWestHills.jpg'
import img3 from './../../Assets/Img/banner/3.CleaningServiceEncinoHousekeeperEncino.jpg'
import img4 from './../../Assets/Img/banner/4.CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg'
import img5 from './../../Assets/Img/banner/5.CleaningServiceValenciaHousekeeperServiceValencia.jpg'
import img6 from './../../Assets/Img/banner/6.CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link} from "react-router-dom";

const Expertise = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    const [slides] = useState([
        {'img': img1 ,'link' : '/residential', 'title': 'Residential', 'description': 'Awesome! the best company that lets you clean and remove    and rubbish anywhere LA county you want.'},
        {'img': img2 ,'link' : '/commercial', 'title': 'Commercial', 'description': 'Awesome! the best company that lets you clean and remove    and rubbish anywhere LA county you want.'},
        {'img': img3 ,'link' : '/carpet-cleaning', 'title': 'Carpet Cleaning', 'description': 'Awesome! the best company that lets you clean and remove    and rubbish anywhere LA county you want.'},
        {'img': img4 ,'link' : '/floor-cleaning', 'title': 'Floor Cleaning', 'description': 'Awesome! the best company that lets you clean and remove    and rubbish anywhere LA county you want.'},
    ])

    return (
        <>
            <section id="services">
                <div className="container gap-1">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h2 className="custom-service bg-color p-4">Our Expertise</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="expertise p-2">
                <div className="container">
                    <Slider {...settings}>
                        {slides.map((slide, index) => (
                            <div key={index}>
                                <Link to={slide.link}>
                                    <div className="img-slider">
                                        <div className="card me-2 shadow p-2 mb-3  expertise">
                                            <img src={slide.img} alt="image"/>
                                        </div>
                                        <h4>{slide.title}</h4>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    );
};

export default Expertise;
