import React from 'react';
import img_1 from './../../Assets/Img/service/CleaningServicesWestHollywoodHousekeeperWestHollywood.jpg'
import Map from "../Includes/Map";

const Contact = () => {
    return (
        <>
            <div className={'container-fluid p-0'}>
                <div className="common-banner">
                    <img src={img_1} alt={'services'} className={'img-fluid'}/>
                    <div className="content">
                        <h1 className={'display-1'}>CONTACT US</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap">
                <section id="contact">
                    <div className="container section-gap">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card shadow h-100">
                                    <div className="card-header bg-color text-white">
                                        <h1>Get In Touch</h1>
                                    </div>
                                    <div className="card-body">
                                        <form action="">
                                            <label htmlFor="" className="w-100 mt-3">
                                                <input type="text" name="name" className="form-control"
                                                       placeholder="Enter Your Name"/>
                                            </label>
                                            <label htmlFor="" className="w-100 mt-3">
                                                <input type="text" name="subject" className="form-control "
                                                       placeholder="Subject"/>
                                            </label>
                                            <label htmlFor="" className="w-100 mt-3">
                                             <textarea type="text" name="message" className="form-control "
                                                       placeholder="Write Your Message"></textarea>
                                            </label>
                                            <div className="form-footer-btn">
                                                <button className="w-100 mt-3 bg-color border-0 text-white  p-2">Send message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card shadow h-100">
                                    <div className="card-header bg-color text-white">
                                        <h1>Contact Us</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="contact-us mt-4">
                                            <p>
                                                <i className="fa fa-home pe-2"></i> 8444 Reseda Blvd. Suite E, Northridge, CA, 91324, USA
                                            </p>
                                            <a href="tel:818773-7599">
                                                <i className="fa fa-phone pe-2"></i> (818) 773-7599
                                            </a>
                                            <a href="mailto:megacarpetcare@gmail.com">
                                                <i className="fa fa-envelope pe-2"></i> megacarpetcare@gmail.com
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Map/>
        </>
    );
};

export default Contact;
