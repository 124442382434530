import React from 'react';
import img_1 from "../../Assets/Img/service/CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg";
import img_4 from "../../Assets/Img/service/CleaningServicesShermanOaksHousekeeperShermanOaks.jpg";
import img_5 from "../../Assets/Img/service/CleaningServicesTarzanaHousekeeperTarzana.jpg";
import img_6 from "../../Assets/Img/service/CleaningServicesWestHollywoodHousekeeperWestHollywood.jpg";
import img_2 from "../../Assets/Img/service/CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg";
import img_3 from "../../Assets/Img/service/CleaningServicesBelAirHousekeeperBelAir.jpg";

const CarpetCleaning = () => {
    return (
        <>
            <div className={'container-fluid p-0'}>
                <div className="common-banner">
                    <img src={img_1} alt={'services'} className={'img-fluid'}/>
                    <div className="content">
                        <h1 className={'display-1 text-uppercase'}>Carpet Cleaning</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_4} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_5} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_6} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={'display-1 text-uppercase text-center'}>Carpet Cleaning</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap service-page">
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> When making an effort to clean your living space, usually many items need your attention. Things like washing dishes, folding clothes or putting out the trash are relatively quick fixes. But unfortunately, nothing can hide dirty carpets. And if you're hoping to get your carpets in tip-top shape, vacuuming is not enough. You should look into professional services, such as HOPE Home Services (818) 773-7599 to get the best result.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> Cleaning a carpet seems like a relatively easy process. But if you want it done right, call us and once you do, you'll realize how much peace of mind you'll have knowing your carpet is receiving the best attention. Getting regular carpet cleanings and providing consistent upkeep will help you prolong the life of your carpet and maintain the look and feel of it for years to come.</p>

            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_1} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_2} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_3} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarpetCleaning;
