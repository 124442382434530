import React from 'react';

const ScheduleBanner = () => {
    return (
        <section id="banner_schedule">
            <div className="container section-gap">

                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="banner-schedule">
                            <div className="row">
                                <div className="col-md-6 border-end">
                                    <h4>Business Hours</h4>
                                    <h6>OPENING DAYS:</h6>
                                    <p>Monday – Friday : <span>7am to 10 pm</span></p>
                                    <p>Saturday :<span>7am to 10 pm</span></p>
                                </div>
                                <div className="col-md-6">
                                    <h4>FOR EMERGENCY CASES</h4>
                                    <h1 className={'mt-4'}><a href="tel:18188930246">1 (818) 893-0246</a></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    );
};

export default ScheduleBanner;
