import React from 'react';
import img_1 from "../../Assets/Img/service/CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg";
import img_4 from "../../Assets/Img/service/CleaningServicesShermanOaksHousekeeperShermanOaks.jpg";
import img_5 from "../../Assets/Img/service/CleaningServicesTarzanaHousekeeperTarzana.jpg";
import img_6 from "../../Assets/Img/service/CleaningServicesWestHollywoodHousekeeperWestHollywood.jpg";
import img_2 from "../../Assets/Img/service/CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg";
import img_3 from "../../Assets/Img/service/CleaningServicesBelAirHousekeeperBelAir.jpg";

const Commercial = () => {
    return (
        <>
            <div className={'container-fluid p-0'}>
                <div className="common-banner">
                    <img src={img_1} alt={'services'} className={'img-fluid'}/>
                    <div className="content">
                        <h1 className={'display-1 text-uppercase'}>Commercial</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_4} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_5} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_6} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={'display-1 text-uppercase text-center'}>Commercial</h1>
                    </div>
                </div>
            </div>
            <div className="container section-gap service-page">
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> Mega Carpet Care Services
                    (818) 773-7599 Residential, Commercial and Offices cleaning services proudly offer the highest
                    quality and most affordable prices for professional house cleaning and maid services in the Los
                    Angeles and San Fernando Valley Areas, Mega Carpet Care Service | Housekeeper Cleaning Services,
                    Residential & Office, Los Angeles County |
                    Mega Carpet Care Service | Housekeeper Cleaning Services, Residential & Office, Beverly Hills , Home
                    Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Calabasas, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Canyon County
                    Mega Carpet Care Service | Housekeeper Cleaning Services, Residential & Office, Century City, Home
                    Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Chatsworth, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Encino, Mega Carpet Care Service |
                    Housekeeper Cleaning Services, Residential & Office, Granada Hills
                    Mega Carpet Care Service | Housekeeper Cleaning Services, Residential & Office, Newhall, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, North Hollywood, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Northridge, Mega Carpet Care Service |
                    Housekeeper Cleaning Services, Residential & Office, Porter Ranch
                    Mega Carpet Care Service | Housekeeper Cleaning Services, Residential & Office, Reseda, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Santa Clarita, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Santa Monica, Mega Carpet Care Service
                    | Housekeeper Cleaning Services, Residential & Office, Sherman Oaks
                    Mega Carpet Care Service | Housekeeper Cleaning Services, Residential & Office, Simi Valley, Home
                    Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Stevenson Ranch, Home
                    Cleaning Services | Housekeeper Cleaning Services, Residential & Office, Tarzana, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Universal City, Home Cleaning
                    Services | Housekeeper Cleaning Services, Residential & Office, Valencia, Mega Carpet Care Service |
                    Housekeeper Cleaning Services, Residential & Office, West Hills, Mega Carpet Care Service |
                    Housekeeper Cleaning Services, Residential & Office, West Hollywood, Mega Carpet Care Service |
                    Housekeeper Cleaning Services, Residential & Office, Westwood, Mega Carpet Care Service | Housekeeper
                    Cleaning Services, Residential & Office, Woodland Hills</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> We can feel overwhelmed and
                    stressed when our homes are out of control; so, too, we can relax when they are clean and orderly.
                    We need to clean up for health, image, and a sense of well-being. Most important, our homes are a
                    place to live, love, relax, and enjoy life.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> We are here to make your life
                    easier and less stressful and to help you find more time in your day. Consider us the busy person’s
                    helper who wants you to enjoy life a little bit more. We do all of your housecleaning needs, freeing
                    up time for you to enjoy the more important things in life.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> Here are some samples of the
                    styles and solutions we provide:</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> Are you experiencing issues with
                    the quality of cleaning services at your facility? Give us an opportunity to solve these issues for
                    you with our superb service and eye for detail.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> HOPE Home Services delivers
                    high-quality, consistent commercial cleaning services to our satisfied customers! Our employees are
                    highly motivated and well trained. Our sales and customer service professionals will design a
                    customized cleaning program for your facility. A personal account manager will be assigned to
                    coordinate quality control efforts and to adjust to any changing needs.</p>
                <p className={'lead mt-4'}><i className="fa-solid fa-square-check"></i> We provide janitorial service
                    for a variety of industries, including the following:</p>
                <ul>
                    <li><i className="fa-solid fa-check-double"></i> General Offices</li>
                    <li><i className="fa-solid fa-check-double"></i> Multi-Tenant Facilities</li>
                    <li><i className="fa-solid fa-check-double"></i> Medical Offices</li>
                    <li><i className="fa-solid fa-check-double"></i> Schools, Preschools and Day Care</li>
                    <li><i className="fa-solid fa-check-double"></i> Churches</li>
                    <li><i className="fa-solid fa-check-double"></i> Auto Dealerships</li>
                    <li><i className="fa-solid fa-check-double"></i> Fitness Centers</li>
                    <li><i className="fa-solid fa-check-double"></i> Restaurants</li>
                </ul>
                <p className={'lead mt-4'}>
                    <i className="fa-solid fa-square-check"></i> The first thing noticed when a client enters your
                    office is your work environment. The chances are, if you're busy servicing your clients, then
                    keeping your work area clean is not always possible. No one likes a dirty work area and the office
                    cleaning companies in our network of professionals do this for a living. That being said, they will
                    aggressively strive for a much higher level of satisfaction. Be it light dusting to industrial deep
                    cleaning, mopping, vacuuming, emptying the trash, and a multitude of other services, our cleaners
                    will service all your office cleaning requirements. The value of using our office cleaning service
                    is to afford you the time to focus on what you do best. We pay attention to our customers’ concerns
                    and needs and strive to provide high quality cleaning services for your company while maintaining
                    and protecting the security of your office.
                </p>
            </div>
            <div className="container section-gap image-containers">
                <div className="row">
                    <div className="col-md-4">
                        <img src={img_1} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_2} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                    <div className="col-md-4">
                        <img src={img_3} className={'img-thumbnail'} alt={'Service Image'}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Commercial;
