import React, {useEffect, useState} from 'react';
import img1 from './../../Assets/Img/banner/1.CleaningServiceWestHollywoodHousekeeperServiceWestHills.jpg'
import img2 from './../../Assets/Img/banner/2.CleaningServiceWestHillsHousekeeperServiceWestHills.jpg'
import img3 from './../../Assets/Img/banner/3.CleaningServiceEncinoHousekeeperEncino.jpg'
import img4 from './../../Assets/Img/banner/4.CleaningServiceNorthridgeHousekeeperServiceNorthridge.jpg'
import img5 from './../../Assets/Img/banner/5.CleaningServiceValenciaHousekeeperServiceValencia.jpg'
import img6 from './../../Assets/Img/banner/6.CleaningServiceWoodlandHillsHousekeeperServiceWoodlandHills.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BASEURL from './../../Constants'
import axios from "axios";
import $ from 'jquery'
import {toast} from "react-toastify";


const SliderHome = () => {

    const [service, setService] = useState([])
    const [input, setInput] = useState({})
    const [error, setError] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getService = () => {
        console.log('work working')
        axios.get(BASEURL + '/api/get-service-list').then(res => {
            setService(res.data)
        })
    }
    const handleInput = (e) => {
        setError([])

        if (e.target.name == 'sign_up') {
            if (e.target.checked == true) {
                $('#sign_up').slideDown()
                setInput(prev => ({...prev, sign_up: 1}))
            } else {
                $('#sign_up').slideUp()
                setInput(prev => ({...prev, sign_up: 0}))
            }
        } else {
            setInput(prev => ({...prev, [e.target.name]: e.target.value}))
        }
    }

    const handleImage = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setInput(prev => ({...prev, photo: reader.result}))
        }
        reader.readAsDataURL(file)
    }
    const handleOrder = () => {
        setIsLoading(true)
        setError([])
        axios.post(BASEURL + '/api/order', input).then(res => {
            toast.success(res.data.msg)
            setIsLoading(false)
            $('#reset_booking').trigger('click')
            setInput({})
        }).catch(errors => {
            if (errors.response.status == 422) {
                setError([])
                setError(errors.response.data.errors)
                setIsLoading(false)
                toast.warn('Something Wrong. Please Fix it')
            }
        })
    }

    const handlePasswordShow = () => {
        let element = $('#password')
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }
    useEffect(() => {
        getService()
        navigator.geolocation.getCurrentPosition(function (position) {
            setInput(prev => ({...prev, longitude: position.coords.longitude}))
            setInput(prev => ({...prev, latitude: position.coords.latitude}))
        });
        setInput(prev => ({...prev, service_id: 1}))
    }, [])

    const handleTab = (e, id) => {
        $(id).show().siblings('.tab-area').hide()
    }

    const settings = {
        dots: false,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [slides] = useState([
        img1, img2, img3, img4, img5, img6
    ])

    const handleSliderForm = () => {
        $('#slider_form').slideToggle()
        let class_list = $('#slider_button').prop("classList")

        if (class_list.value == 'fa-solid fa-circle-xmark custom-icon'){
            $('#slider_button').removeClass('fa-solid fa-circle-xmark custom-icon').addClass('fa-regular fa-window-maximize custom-icon')
        }else{
            $('#slider_button').removeClass('fa-regular fa-window-maximize custom-icon').addClass('fa-solid fa-circle-xmark custom-icon')

        }
    }

    return (
        <section id="slider_area">
            <div className="container-fluid g-0">
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index}>
                            <div className="slider-item">
                                <img className="banner-img " src={slide} alt="image"/>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>


            <div className="slider-form mt-0">
                <i onClick={handleSliderForm} id={'slider_button'} className="fa-solid fa-circle-xmark custom-icon"></i>
                <div className="card" id={'slider_form'}>
                    <div className="card-body">
                        <div className="btn-group form-btn">
                            <button onClick={(e) => handleTab(e, '#service_booking')} type="button" className="bg-color border-0 ">Booking A Service</button>
                            <button onClick={(e) => handleTab(e, '#register')} type="button" className="bg-color border-0">Register</button>
                        </div>
                        <div className="service-book tab-area" id={'service_booking'}>
                            <form>
                                <label>
                                    Name
                                    <input
                                        onChange={handleInput}
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Enter Your Full Name"
                                        name="name"
                                    />
                                    {Object.entries(error).map(([key, val]) => (
                                        <small
                                            className="text-danger validation-error-message"
                                            key={key}>{key == 'name' ? val : ''}
                                        </small>
                                    ))}
                                </label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>
                                            Phone
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                placeholder="Enter Your Phone Number"
                                                name="phone"
                                                onChange={handleInput}
                                            />
                                            {Object.entries(error).map(([key, val]) => (
                                                <small
                                                    className="text-danger validation-error-message"
                                                    key={key}>{key == 'phone' ? val : ''}
                                                </small>
                                            ))}
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>
                                            Email
                                            <input
                                                type="email"
                                                className="form-control form-control-sm"
                                                placeholder="Enter Your Email"
                                                name="email"
                                                onChange={handleInput}
                                            />
                                            {Object.entries(error).map(([key, val]) => (
                                                <small
                                                    className="text-danger validation-error-message"
                                                    key={key}>{key == 'email' ? val : ''}
                                                </small>
                                            ))}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>
                                            Select Service
                                            <select

                                                onChange={handleInput}
                                                // onInputChange={handleSelect}
                                                value={input.service_id}
                                                name={'service_id'}
                                                className={'form-select form-select-sm'}
                                            >
                                                {service.map((serv, index) => (
                                                    <option key={index} value={serv.value}>{serv.label}</option>
                                                ))}
                                            </select>
                                            {Object.entries(error).map(([key, val]) => (
                                                <small
                                                    className="text-danger validation-error-message"
                                                    key={key}>{key == 'service_id' ? val : ''}
                                                </small>
                                            ))}
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>
                                            Select Time
                                            <input
                                                type="datetime-local"
                                                className="form-control form-control-sm"
                                                name="time"
                                                onChange={handleInput}
                                            />
                                            {Object.entries(error).map(([key, val]) => (
                                                <small
                                                    className="text-danger validation-error-message"
                                                    key={key}>{key == 'time' ? val : ''}
                                                </small>
                                            ))}
                                        </label>
                                    </div>
                                </div>
                                <label>
                                    Address
                                    <input
                                        type={"text"}
                                        className="form-control form-control-sm"
                                        placeholder="Enter Your Address"
                                        name="address"
                                        onChange={handleInput}
                                    />
                                    {Object.entries(error).map(([key, val]) => (
                                        <small
                                            className="text-danger validation-error-message"
                                            key={key}>{key == 'address' ? val : ''}
                                        </small>
                                    ))}
                                </label>
                                <label>
                                    Description
                                    <textarea
                                        className="form-control form-control-sm"
                                        placeholder="Enter Description"
                                        name="description"
                                        onChange={handleInput}
                                    />
                                    {Object.entries(error).map(([key, val]) => (
                                        <small
                                            className="text-danger validation-error-message"
                                            key={key}>{key == 'description' ? val : ''}
                                        </small>
                                    ))}
                                </label>
                                <label>
                                    Photo
                                    <input
                                        className="form-control form-control-sm"
                                        type="file"
                                        name={'photo'}
                                        onChange={handleImage}
                                    />
                                </label>
                                <label>
                                    <input onInput={handleInput} name={'sign_up'} type={'checkbox'} className={'form-check-input me-2'}/>
                                    Want to signup for further order tracking?
                                </label>

                                <div id="sign_up" style={{display: 'none'}}>
                                    <label>
                                        Password
                                        <div className="position-relative">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                placeholder="Enter Your Password"
                                                name="password"
                                                onChange={handleInput}
                                                id={'password'}
                                            />
                                            <div onClick={handlePasswordShow} className="eye-button-password"><i
                                                className={'fas fa-eye'}/></div>
                                        </div>

                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'password' ? val : ''}
                                            </small>
                                        ))}
                                    </label>

                                </div>

                                <div className="d-grid mt-3">
                                    <button id={'reset_booking'} className={'d-none'} type={"reset"}/>
                                    <button type={'button'} onClick={handleOrder} className="btn btn-success btn-sm"
                                            dangerouslySetInnerHTML={{__html: isLoading ? ' <div class="spinner-border spinner-border-sm"></div>' : 'BOOK SERVICE'}}/>
                                </div>
                            </form>
                        </div>
                        <div className="registration tab-area" id={'register'} style={{display: 'none'}}>
                            <form>
                                <label>Name
                                    <input type="name" className="form-control form-control-sm" placeholder="Enter Your Full Name"/>
                                </label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Phone
                                            <input type="number" className="form-control  form-control-sm"
                                                   placeholder="Enter Your Phone"/>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Email
                                            <input type="email" className="form-control  form-control-sm"
                                                   placeholder="Enter Your Email"/>
                                        </label>
                                    </div>
                                </div>

                                <label>Address
                                    <input type={"text"} className="form-control  form-control-sm" placeholder="Enter Your Address"/>
                                </label>
                                <label>Password
                                    <input type="password" className="form-control  form-control-sm"
                                           placeholder="Enter Your Password"/>
                                </label>
                                <label>Confirm Password
                                    <input type="password" className="form-control  form-control-sm" placeholder="Confirm Password"/>
                                </label>
                                <div className="d-grid mt-3">
                                    <button className="btn btn-sm btn-info text-uppercase">Download W9 Form</button>
                                </div>
                                <label>
                                    Upload W9 Form<input className="form-control  form-control-sm" type="file"/>
                                </label>
                                <div className="d-grid mt-3">
                                    <button className="btn btn-success">REGISTER</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    );
};

export default SliderHome;
