import './App.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import './Assets/Styles/style.scss'
import Home from "./Compoents/Pages/Home";
import {BrowserRouter, Route} from "react-router-dom";
import TopBar from "./Compoents/Includes/TopBar";
import NavBar from "./Compoents/Includes/NavBar";
import React from "react";
import Footer from "./Compoents/Includes/Footer";
import About from "./Compoents/Pages/About";
import Contact from "./Compoents/Pages/Contact";
import BlogList from "./Compoents/Pages/BlogList";
import Services from "./Compoents/Pages/Services";
import Residential from "./Compoents/Pages/Residential";
import Commercial from "./Compoents/Pages/Commercial";
import CarpetCleaning from "./Compoents/Pages/CarpetCleaning";
import FloorCleaning from "./Compoents/Pages/FloorCleaning";
import PrivacyPolicy from "./Compoents/Pages/PrivacyPolicy";

function App() {
    return (
        <>
            <BrowserRouter>
                <TopBar/>
                <NavBar/>
                    <Route exact path={'/'} component={Home}/>
                    <Route exact path={'/about'} component={About}/>
                    <Route exact path={'/contact'} component={Contact}/>
                    <Route exact path={'/blog'} component={BlogList}/>
                    <Route exact path={'/services'} component={Services}/>
                    <Route exact path={'/residential'} component={Residential}/>
                    <Route exact path={'/commercial'} component={Commercial}/>
                    <Route exact path={'/carpet-cleaning'} component={CarpetCleaning}/>
                    <Route exact path={'/floor-cleaning'} component={FloorCleaning}/>
                    <Route exact path={'/privacy-policy'} component={PrivacyPolicy}/>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;
