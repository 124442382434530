import React from 'react';

const Testimonial = () => {
    return (
        <section id="clint-feedback">
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12 py-2 custom-service bg-color">
                        <h2 className="text-center text-capitalized">Testimonies</h2>
                        <h5 className="text-center text-capitalized theme-color">Happy Clients &amp; Feedbacks</h5>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card p-4 border-0 shadow position-relative">
                            <p className="feadback-text">
                                <i className="fas left-icon fa-quote-left"></i>
                                 Service is really appreciable. Good service and
                                reasonable.Thanks.
                                <i className="fas right-icon fa-quote-right"></i>
                            </p>
                            <figcaption className="blockquote-footer text-center pt-3">Jane Doe, Frankfurt
                            </figcaption>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card p-4 border-0 shadow position-relative">
                            <p className="feadback-text">
                                <i className="fas fa-quote-left left-icon"></i>
                                 Service is really appreciable. Good service and
                                reasonable.Thanks.
                                <i className="fas fa-quote-right right-icon"></i>
                            </p>
                            <figcaption className="blockquote-footer text-center pt-3">Jane Doe, Frankfurt
                            </figcaption>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
};

export default Testimonial;
