import React, {useEffect, useState} from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const CountryLink = () =>{

    useEffect(() => {
        getCountryLink()
    }, []);
    const [isModalOpen, setModalStatus] = useState(false);
    const [countryLink, setcountryLink] =useState([
        {name: 'Mega Carpet Care, Shower Pan, Residential, Commercial, San Fernando Valley'},
        {name: 'Mega Carpet Care, Shower Pan, Residential, Commercial, San Fernando Valley'},
        {name: 'Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles'},
        {name: 'Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles'},
        {name: 'Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles'},
    ])

    const [countryLink1, setCountryLink1] = useState({})
    const [countryLink2, setCountryLink2] = useState({})
    const [countryLink3, setCountryLink3] = useState({})
    const [countryLink4, setCountryLink4] = useState({})
    const [countryLink5, setCountryLink5] = useState({})

    const getCountryLink =() =>{
        axios.get('https://www.api.megahotmop.com/api/country-link/1').then(res=>{
            setCountryLink1(res.data)
        })
        axios.get('https://www.api.megahotmop.com/api/country-link/2').then(res=>{
            setCountryLink2(res.data)
        })
        axios.get('https://www.api.megahotmop.com/api/country-link/3').then(res=>{
            setCountryLink3(res.data)
        })
        axios.get('https://www.api.megahotmop.com/api/country-link/4').then(res=>{
            setCountryLink4(res.data)
        })
        axios.get('https://www.api.megahotmop.com/api/country-link/5').then(res=>{
            setCountryLink5(res.data)
        })
    }
    const [singleLink, setSingleLink] = useState({})
    const handleModal = (id, e) =>{
        axios.get('https://www.api.megahotmop.com/api/single-country-link/'+id).then(res=>{
            setSingleLink(res.data)
            setModalStatus(true)
        })
    }
    return(
        <>
           <div className="container">
               <div className="row justify-content-center">
                       <div className="col-md-6 my-4">
                           <div className="list-group-item list-group-item-action color rounded px-3 py-2">
                               <h4 className="text-white">Mega Carpet Care, Shower Pan, Residential, Commercial, San Fernando Valley</h4>
                           </div>
                           <div className="list-group">
                               {Object.keys(countryLink1).length>0? countryLink1.map((item, linkItem)=>(
                                   <li onClick={(e) => handleModal(item.id, e)} className="list-group-item list-group-item-action custom-list-item" key={linkItem}>{item.title != undefined ? item.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}</li>
                               )):null}
                           </div>
                       </div>
                       <div className="col-md-6 my-4">
                           <div className="list-group-item list-group-item-action color rounded px-3 py-2">
                               <h4 className="text-white">Mega Carpet Care, Shower Pan, Residential, Commercial, San Fernando Valley</h4>
                           </div>
                           <div className="list-group">
                               {Object.keys(countryLink2).length>0? countryLink2.map((item, linkItem)=>(
                                   <li onClick={(e) => handleModal(item.id, e)} className="list-group-item list-group-item-action custom-list-item" key={linkItem}>{item.title != undefined ? item.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}</li>
                               )):null}
                           </div>
                       </div>
                       <div className="col-md-6 my-4">
                           <div className="list-group-item list-group-item-action color rounded px-3 py-2">
                               <h4 className="text-white">Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles</h4>
                           </div>
                           <div className="list-group">
                               {Object.keys(countryLink3).length>0? countryLink3.map((item, linkItem)=>(
                                   <li onClick={(e) => handleModal(item.id, e)} className="list-group-item list-group-item-action custom-list-item" key={linkItem}>{item.title != undefined ? item.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}</li>
                               )):null}
                           </div>
                       </div>
                       <div className="col-md-6 my-4">
                           <div className="list-group-item list-group-item-action color rounded px-3 py-2">
                               <h4 className="text-white">Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles</h4>
                           </div>
                           <div className="list-group">
                               {Object.keys(countryLink4).length>0? countryLink4.map((item, linkItem)=>(
                                   <li onClick={(e) => handleModal(item.id, e)} className="list-group-item list-group-item-action custom-list-item" key={linkItem}>{item.title != undefined ? item.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}</li>
                               )):null}
                           </div>
                       </div>
                       <div className="col-md-6 my-4">
                           <div className="list-group-item list-group-item-action color rounded px-3 py-2">
                               <h4 className="text-white">Mega Carpet Care, Shower Pan, Residential, Commercial, Los Angeles</h4>
                           </div>
                           <div className="list-group">
                               {Object.keys(countryLink5).length>0? countryLink5.map((item, linkItem)=>(
                                   <li onClick={(e) => handleModal(item.id, e)} className="list-group-item list-group-item-action custom-list-item" key={linkItem}>{item.title != undefined ? item.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}</li>
                               )):null}
                           </div>
                       </div>
               </div>
           </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isModalOpen}
                onHide={() => setModalStatus(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {singleLink.title != undefined ? singleLink.title.replaceAll('Hot Mop', 'Mega Carpet Care') : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html:singleLink.details != undefined ? singleLink.details.replaceAll('Hot Mop', 'Carpet Care') : null}}>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export  default CountryLink
