import React from 'react';

const Map = () => {
    return (
        <section id="map">
            <div className="container-fluid mt-5">
                <iframe src="https://www.google.com/maps/d/embed?mid=1z_VJ1n9UrsBc8C-jlRbGtKfPtNqLmnkm&amp;ehbc=2E312F"
                        width="100%" height="400" frameBorder="0" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </section>
    );
};

export default Map;
