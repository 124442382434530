import React from 'react';
import Blog from "../Includes/Blog";

const BlogList = () => {
    return (
        <div className={'mb-5'}>
           <Blog/>
        </div>
    );
};

export default BlogList;
